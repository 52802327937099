.Profilesection {
  background: #f6f6f8;
  /* padding: 50px 0px; */
  padding-bottom: 50px;
  background: #f6f6f8;
}
.planListData .sub-list span img {
  margin-right: 8px;
}
.priceWrap {
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 12px;
}
.ContestBannerSection {
  background-color: #212844;
  border-radius: 20px;
  padding: 0px 40px;
  height: 290px;
}
.ContestbannerHeading {
  color: #ebeaea;
}
.contesthomeform {
  color: #212844;
  font-size: 24px;
  line-height: 28px;
  margin: 32px 0px;
  margin-top: 0;
}

.fieldWrappeer label
{
  color: #070c20b3;
}

.fieldWrappeer input
{
  margin-top: 10px;
}

.communityProfileLink .ant-list-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.communityProfileLink .ant-list-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.profilewrap {
  padding: 20px 40px;
}
.border {
  border-bottom: 1px solid #e3e4e4;
  margin-bottom: 15px;
  margin-top: -10px;
}
.Inputcolor {
  background-color: #f9f9f9;
  border-radius: 5px;
  height: 40px;
  font-size: 15px;
  border: 1px solid #eae8e8;
  width: 100%;
}
.personalInfoUser input {
  background-color: #f9f9f9;
  border-radius: 5px;
  height: 40px;
  font-size: 15px;
  border: 1px solid #eae8e8;
  width: 100%;
  box-sizing: border-box;
 padding: 0 10px;
}
.Inputcolor:focus,
.personalInfoUser input:focus {
  border: 1px solid #212844;
}
.personalInfoUser label {
  color: rgba(7, 12, 32, 0.7);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

.contactForm input {
  border-radius: 4px;
  color: #939dc0;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.contactForm input::placeholder,
.contactForm textarea::placeholder {
  color: #939dc0;
}
.contactForm label {
  color: #d8d8d8 !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.contactForm {
  margin-top: 50px;
}

.imgFluid {
  width: 100%;
}

.contactForm textarea {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #939dc0;
}

.contactForm .form-check-label {
  padding-left: 0;
}

.rightCheckbox .ant-checkbox-inner {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 17px;
  height: 17px;
}

.contactBtn {
  margin-top: 20px !important;
  display: flex;
  height: 47px !important;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 60px;
  background: #db0a40;
}

.contactBtn:hover {
  background: #0a0a0a !important;
  color: #fff !important;
}

.btnsubmit {
  float: right;
  background-color: #db0a40;
  padding: 12px 16px 12px 16px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 60px;
  cursor: pointer;
  /* margin-right: 3%; */
}
.btnsubmit:hover {
  background-color: #428dff;
}
.fieldWrappeer {
  margin-bottom: 20px;
}

/* COUPON CODES */
.couponrowrap {
  border: 2px solid #e6e6e6;
  margin: 10px 0px;
  padding: 4px 0px;
  border-radius: 4px;
  display: inline-block;
  justify-content: space-around;
}
.pr-5 {
  padding-right: 5px;
}
.addCardBtn {
  border-radius: 60px;
  background: #000;
  padding: 7px 16px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.addCardBtn:hover{background:#DB0A40; border-color: transparent;}
.saveCardBtn {
  border-radius: 60px;
  background: #db0a40;
  padding: 7px 16px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
}
.saveCardBtn:hover{background: #000; color: #fff !important; border-color:transparent !important;}
.saveCardWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saveCardForm label{ 
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;}
.SaveCardHistoryWrap{margin-top: 30px;}
.cardDetailsText{color: #ACACAC !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px;}
.cardBtnWrap{display: flex; justify-content: space-between; align-items: center;}
.communityProfileLink li {
  padding: 8px 16px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.communityProfileLink {
  background: #fff;
  border-radius: 10px;
}

.communityProfileLink .ant-anchor-link a {
  color: #000;
  position: relative;
}
.debitCardWrap input {
  border-radius: 6px;
  height: 40px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.debitCardWrap input:focus {
  border-color: #000;
}
.couponimage {
  padding: 15px 2px 0px 10px;
}
.coupondescription {
  justify-content: space-between;
  margin-bottom: 0px !important;
}
.verifywraper {
  color: #10b981;
  font-size: 14px;
  line-height: 12.5px;
  font-weight: 400;
  display: inline-block;
}
.showcodewrap {
  background-color: #db0a40;
  color: #f9f9f9;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 0px;
  border-radius: 40px;
  line-height: 24px;
  text-align: center;
}

.couponhead {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
  line-height: 21.78px;
  margin-bottom: 7px;
}
.coupondescription {
  margin: -5px 0px 10px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-right: 5%;
}
.verifywraper span {
  margin: 5px;
}
.content {
  font-size: 14px;
  font-weight: 400;
}
/* .verifywraper span{
    margin-top: 10pc !important;
  } */
/* .verifywraper .verifytitle{
    padding-top: -10px !important;
    margin-top: -10px !important;
  } */

/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  } */

/* body {
    background-color: #f2f2f2;
  } */
/*   
  .container {
    max-width: 650px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */

.wrapper {
  border-bottom: 1px solid #eaecf0;
  overflow: hidden;
}

.wrapper .question-container {
  width: 100%;
  text-align: left;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400 !important;
  font-size: 20px !important;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #101828;
  line-height: 28px;
  padding-top: 15px;
}

.question-container.active {
  color: #101828;
  line-height: 28px;
  font-size: 20px !important;
  padding-top: 15px;
  font-weight: 200 !important;
}

.wrapper .question-container:hover {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );
}

.wrapper .arrow {
  font-size: 2rem;
  transition: 0.1s ease-in-out;
}

.arrow.active {
  rotate: 180deg;
  color: #1db954;
}

.wrapper .answer-container {
  /* padding: 0 1rem; */
  transition: height 0.7s ease-in-out;
}

.wrapper .answer-content {
  padding: 0rem 0px 10px 0px;
  font-size: 16px;
  font-weight: 400;
  width: 98%;
  color: #667085;
  height: auto;
}
.tandcwrap p {
  font-size: 15px;
  color: #3b3a3b;
  letter-spacing: 0.3px;
  font-weight: 400;
  width: 100%;
}
.question-content {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

/* NOTIFICATIONS ---------- */
.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.1s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #10b981;
}

input:focus + .slider {
  box-shadow: 0 0 1px white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.notificationdescription {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #667085;
}
.notificationhead {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
  margin-bottom: -10px;
}
.notificationwrap {
  border: 2px solid #e6e6e6;
  margin: 12px 0px;
  padding: 1px 0px;
  border-radius: 4px;
  display: inline-block;
  justify-content: space-evenly;
}
.notificationimage {
  padding: 10px 0px 0px 10px;
}
/* NOTIFICATIONS END----------- */

/* 
<----------------HELP AND CENTER CSS STARTS HERRE---------> */

.boxwrapper {
  height: 180px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 16px 10px;
  margin: 25px 0px;
}
.discriptionwrap p {
  padding: 2px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.boxwrapper h2 {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

/* <----------------HELP AND CENTER CSS ENDS HERE-------> */

/* <----------------Subscription CSS STARTS HERE-------> */
.subscriptionhead {
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  margin: 0px;
}
.subscription-description {
  font-size: 20px !important;
  font-weight: 400;
  color: #667085 !important;
}
.subs-boxwrapper {
  /* height: 300px; */
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 5px 5px;
  margin: 0 0 25px 0px;
  align-items: center;
  text-align: center;
}
.active-subs-boxwrapper {
  /* height: 300px; */
  border: 1px solid #131F58;
  border-radius: 8px;
  padding: 5px 5px;
  margin: 0 0 25px 0px;
  align-items: center;
  text-align: center;
}
.subs-cardhead {
  font-size: 30px;
  font-weight: 800;
  line-height: 60px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.subs-plan {
  font-size: 20px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 0;
}
.subs-billed {
  font-size: 16px;
  font-weight: 400;
  color: #667085;
  margin-top: 5px;
  margin-bottom: 10px;
}
.sub-list {
  margin: -10px 0px;
  text-align: left;
  justify-content: baseline;
  padding-left: 10%;
}

.planListData .sub-list {
  padding-left: 0;
}

.planListData .sub-list span {
  padding-right: 0;
}

.sub-list p {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}
.sub-btn {
  margin-bottom: 15px;
  background-color: #db0a40;
  padding: 10px 80px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 60px;
  cursor: pointer;
}
.Activesub-btn {
  margin-bottom: 15px;
  background-color: #131F58;
  padding: 10px 80px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  border-radius: 60px;
  cursor: pointer;
}
.sub-btn:hover {
  background-color: #428dff;
  cursor: pointer;
}

.planListData {
  padding: 15px;
  padding-bottom: 5px;
}

.notificationwrap .ant-row{justify-content: space-between;}

/* <----------------Subscription CSS END HERE-------> */

/* <--------------------SUBSCRIPTION PAYMENT CSS STARTS HERE--------------> */
.paymentboxwrapper {
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 6px 1px rgba(78, 78, 78, 0.2),
    1px 1px 1px 1px rgba(95, 95, 95, 0.19);
}
.sub-paymentdiscriptionwrap {
  /* padding: 1px; */
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.sub-paymentdiscriptionwrap .sub-head {
  color: #101828 !important;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.sub-para {
  margin: 30px 0px 0px 0px;
  font-size: 14px;
  color: #667085;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.helpForm {
  margin-top: 20px;
}

.helpForm label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
}

.sendNowHelp {
  margin-top: 30px;
  text-align: center;
}

.sendNowHelp button {
  border-radius: 4px;
  background: #3c2e3c;
  color: #fff;
  min-width: 250px;
  min-height: 41px;
}

.contactHelpBox p {
  text-align: center;
  color: #3c2e3c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.contactHelpBox {
  text-align: center;
}

.contactHelpBox .ant-anchor-link {
  color: #db0a40;
}

.contactHelpBox h4 {
  margin-top: 10px;
}

.helpCOntactDetail {
  margin-top: 80px;
}

.contactHelpBox .IcoBox img {
  width: 50px;
}
.communityProfileLink svg{    border-radius: 6px; margin-right: 5px;
  background: #DB0A40;
  fill: #fff;
  width: 17px;

  padding: 3px;}

.communityProfileLink .ant-list-item:hover {
  cursor: pointer;
}

.communityProfileLink .ant-anchor-link-active{display: flex; align-items: center;}

.withActiveBG{background: #FFFAFB;}

.withoutActiveBG svg{border-radius: 100px;
  background: #F4F5F7;  fill: #000;}
/* <--------------------SUBSCRIPTION PAYMENT CSS STARTS HERE--------------> */
