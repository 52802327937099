
.sidebarWrap a.ps-open{ 
    background:#560909;
 }

.sidebarWrap{background-color: transparent !important; font-size: 16px;}

.sidebarWrap  .css-z5rm24 a{color: #ede2e2;
    height: inherit;
    padding: 5px 10px;
    margin-left: 25px;
    padding-left: 20px; cursor: pointer; 
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;}

.sidebarWrap  .css-z5rm24 a:before{
    content: "";
    height: 0.125rem;
    width: 0.375rem;
    background: #FB9B1E;
    position: absolute;
    left: 13%;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s; 
}

.sidebarWrap  .css-z5rm24 a:hover:before {
    width: 1.25rem;
}

.sidebarWrap  .css-z5rm24 a:hover {
    padding-left: 37px;
}

.css-1tqrhto >.ps-menu-button:hover{background-color: transparent !important;}

.bodyBg .css-zwiamn{margin: 62px 20px 0 !important;
    padding: 10px 0 35px;}

.sidebarWrap{box-shadow: 0px 0px 15px #2d455e1a;} 

.css-1m3bo6{margin-left: 0 !important;}

.fpTHfu {
    background-color: yellow !important;
  }

/* .css-1t8x7v1 >.ps-menu-button{ padding-right: 1px !important;
    padding-left: 5px !important;} */