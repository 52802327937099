.clearfix {
    clear: both;
  }

  .moreComments{color: #03a1fc;
    padding: 10px 20px;
    cursor: pointer;}

  .courseCommunity {
    background: #fff;
    border-radius: 10px;
    padding: 20px 20px;
  }
  
  .aboutCommunity h2 {
    border-bottom: 1px #f2f4f7 solid;
    padding: 15px 0;
    margin-bottom: 25px;
    margin-top: 0;
  }

  .createPostWrap{background: #f3f3f3;
    border-radius: 12px;
    padding: 24px;
    display: flex; margin-bottom: 18px;
    align-items: center;flex-wrap: wrap;}

  .searchPost:focus{border:1px #000 solid;}

  .createPostAvtar{    width: 48px;
    height: 48px;
    border-radius: 50px;
    margin-right: 12px;
    max-width: 8%;
    flex: 0 0 8%;}

 .searchPost{ border-radius: 100px;
    padding: 10px 14px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); }

.modalPost .ant-modal-footer{display: flex; }

.modalPost .ant-btn-primary{ background:#DB0A40;}

.modalPost .ant-modal-footer button{width: 50% !important;}

.createPostInput{max-width: 90%; flex:0 0 90%;}

  .communityLeftLink {
    background: #fff;
    border-radius: 10px;
  }
  
  .communityLeftLink .ant-list-bordered {
    border: none;
    border-radius: 0;
  }
  
 
  
  .communityLeftLink svg{    border-radius: 100px;
    background: #DB0A40;
    fill: #fff;
    width: 17px;
    margin-right: 5px;
    padding: 3px;}
  
  .communityLeftLink .ant-list-item:hover {
    cursor: pointer;
  }
 
  .communityLeftLink .ant-anchor-link-active{display: flex; align-items: center;}

  .withActiveBG{background: #FFFAFB;}

  .withoutActiveBG svg{border-radius: 6px;
    background: #F4F5F7;  fill: #000;}

    .withActiveBG:first-child{border-top-left-radius: 10px; border-top-right-radius: 10px;}

    .withActiveBG:last-child{border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
  
  .pDiscriptionBg {
    background: #fcf8f8;
    padding: 10px;
    margin-top: 25px;
    color: #000;
  }
  
  .subscribeBtn {
    border-radius: 40px !important;
    background-color: #db0a40 !important;
    color: #f7f8f9 !important;
    font-weight: 500;
    border: none;
  }
  
  .subscribeBtn:hover {
    background-color: #000 !important;
  }
  
  .joinCommunity:hover {
    background: #0a0a0a !important;
    color: #fff !important;
  }
  
  .courseDescription {
    color: #101828;
    font-size: 18px;
    line-height: 30px;
  }
  
  .CourseImg img {
    border-radius: 16px;
    width: 100%;
  }
  
  .courseDetails h2 {
    margin-top: 0;
    font-size: 28px;
  }
  
  .courseRatings {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ratings {
    font-size: 16px;
    font-weight: 400;
  }
  .ratingStar {
    color: #ffba00;
  }
  
  .ratingCount {
    color: #8f8c8c;
    padding-left: 5px;
  }
  
  .rapperCount {
    color: #101828;
    padding-left: 5px;
  }
  
  .courseTrendingList {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  
  .courseTrendingList .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 2px #f2f4f7 solid !important;
  }
  
  .courseTrendingList .ant-tabs .ant-tabs-tab {
    color: #111;
  }
  
  .courseTrendingList
    .ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #db0a40;
  }
  
  .courseTrendingList .ant-tabs .ant-tabs-ink-bar {
    background: #db0a40;
  }
  
  .trendingCourseWrap h3 {
    margin-top: 7px;
    border-bottom: 2px #f2f4f7 solid;
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  
  .trendingCourseBox h4 {
    color: #db0a40;
    font-size: 17px;
    text-transform: capitalize;
    margin-top: 0;
  }
  
  .courseImg {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    height: 240px;
  }
  
  .courseImg img {
    border-radius: 10px;
    width: 100%;
    transition: 0.4s;
    object-fit: cover;
    height: 100%;
  }
  
  .courseImg:hover img {
    transform: scale(1.1);
  }
  
  .courseTimeDuration {
    color: #101828;
    font-size: 14px;
  }
  
  .trendingCourseBox {
    cursor: pointer;
  }
  
  .trendingCourseWrap {
    margin-bottom: 45px;
  }
  
  .trendingCourseWrap:last-child {
    margin-bottom: 0;
  }
  
  .communityPostBox {
    border: 1px #dbdcde solid;
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: 100px;
  }
  
  .communityPostName h5 {
    margin: 0;
    color: #050505;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }
  
  .communityPostName {
    padding-left: 10px;
      display: flex;
      width: 92%;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  
  .communityPostUserImg {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  .communityPostUserImg img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
  }
  .communityPostHeading {
    padding: 20px;
  }
  .communityPostInner {
    display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
  }
  
  .CommunitySmallText {
    color: #65676b;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    display: flex;
    align-items: center;
  }
  
  .communityPostName svg {
    width: 12px;
    height: 12px;
  }
  
  .spaceBoth {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .CommunityuserVerified svg {
    color: #399ef4 !important;
  }
  
  .communityPostDescription {
    color: #050505;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    margin-top: 10px;
  }
  
  .communityPostImg img{width: 100%;
  
    object-fit: contain;}

  .communityPostImg{height: 400px; overflow: hidden;}
  
  .communityActivity{ border-top: 1px #CED0D4 solid;
    border-bottom: 1px #CED0D4 solid;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;}
  
    .communityActivity a{color: #65676B;}
    
    .commentImg a,.shareImg a{display: flex;
      align-items: center;}

    .shareImg{display: flex;
      align-items: center;}
  
.commentImg img{width: 16px; padding-right: 5px;}

  .commentImg{display: flex;
    align-items: center;}
  
   .communityActivity span{padding-right: 6px;}
  
   .communityDoComment{ display: flex;
    align-items: center;
    padding: 10px 20px;
    flex-wrap: wrap;
    justify-content: space-between;}

  .likeCountWrap span .anticon-like{background: #03a1fc;
    color: #fff;
    border-radius: 50px;
    padding: 4px;}

  .commentCount{}
  
   .commentUserImg{
    max-width: 32px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* margin-right: 10px; */
      flex: 0 0 auto;}

    .sendIcon svg{ font-size: 25px;
      justify-content: center;
      fill: #6b6b6b;}
  
    .commentUserImg img{width: 100%; border-radius: 100%; height: 100%;}
  
    .commentUserInput input{border-radius: 86px;
      background: #F0F2F5; height: 33px;}
  
    .commentUserInput{width: 87%;
      max-width: 87%;
      flex: 0 0 auto;
      margin: 0 6px;}

      .commentUserModalInput{width: 82%;
        max-width: 87%;
        flex: 0 0 auto;
        margin: 0 6px;}
  
      .commentUserInput  .ant-input:hover {
        border-color: #db0a40;
    }

    
  
    .commentUserInput  .ant-input:focus{box-shadow: 0 0 0 2px rgba(219, 10, 64, 0.1);}
  
    .communityAdBanner .mb-30{margin-bottom: 30px;}
  
    .communityAdBanner  img{width: 100%;}
  
    .threeDot svg{width: 25px;
      height: 25px;}
  
    .postRelatedAction button{padding: 0;
      border: 0;}

.likeCommentBox{display: flex; align-items: center; justify-content: space-between;padding: 10px 20px; cursor: pointer;
}

.likeCountWrap{display: flex; align-items: center; }

.likeCount{ padding-left: 4px;}

.commentBox{display: flex; 
  align-items: center; 
  padding: 10px 20px;
 }

 .commentModalBox{display: flex; 
  align-items: center; 
  padding: 10px 20px;
 }

.commentTextWrap{ margin-left: 10px}

.commentTextArea{ background: #f0f2f5;
  padding: 5px 10px;
  border-radius: 13px;
 }

.commmentName{font-weight: 600;  display: block;}

.commmentData{ display: block; line-height: normal;}

.commentDate{color: #65676b;
  padding-left: 10px;
  font-size: 12px;
  display: block;}

.commentAreaWrap{ border-bottom: 1px #CED0D4 solid;}