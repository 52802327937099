.clearfix{clear: both;}

.aboutCommunity{background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    padding-bottom: 30px;
}

.aboutCommunity p{color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin-top: 0;}

.aboutCommunity h2{border-bottom: 1px #f2f4f7 solid;
  padding: 15px 0; margin-bottom: 25px;
  margin-top: 0;  }

.pDiscription{color: #776d77;     margin-top: 5px;}

.communityTextWrap{margin-bottom: 25px;}

.communityTextWrap h4{margin-bottom: 5px;  margin-top: 15px;}
.textClamp{display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;}



.communityLeftLink  .ant-list-item:hover{cursor: pointer;}


.pDiscriptionBg{background: #fcf8f8;
    padding: 10px;
    margin-top: 25px;
    color: #000;}

.courseDetailsList span{color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; display: flex;align-items: flex-start;}

.courseDetailsList .ant-col{padding: 10px 0;}

.courseDetailsList span svg{width: 30px;
  font-size: 17px;}

.text-Black{color: #000 !important;}

.courseCOntent .ant-collapse{  background-color: #F4F4F4; border-radius: 0; border:none;}

.courseWrapContent{border: 1px solid #d9d9d9;
  padding: 8px;}

  .courseWrapContent  .ant-collapse-header{  border: 1px #d9d9d9 solid;}

  .courseWrapContent .ant-collapse .ant-collapse-content{border-top: 0;}

  .courseWrapContent .ant-collapse>.ant-collapse-item{border-bottom: none;}

  .courseWrapContent .ant-collapse-header{border-radius: 0 !important;}

  .courseDuration{color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;}

  .courselabel{color: #000;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;}

   .couseInfo li {}

   .courselabel svg{margin-right: 5px;}

   .ant-collapse-header-text{font-weight: 600;}

   .CourseVideos{background: #fff;
    border-radius: 10px;
      margin-top: 20px;
    padding-bottom: 30px;}

  .courseBoxRight h4{color: #DB0A40;
    font-family: Inter;
    margin-bottom: 14px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;}

  .courseBoxRight{border-bottom: 1px #d5d7d9 solid; padding: 20px;}

  .courseBoxRight .courseDuration{color: #101828; margin-bottom: 0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;}

  .courseBoxRight .courseImg img{width: 100%;}

  .courseBoxRight .coursevideo img{width: 100%;}

  .courseVideoListWrap{display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; padding: 20px;}

  .courseVideoImg{width: 100px;
    height: 90px;
    margin-right: 13px;
    overflow: hidden;}

  .courseVideoContent{-webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0;}

  .courseVideoContent h4{color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 0;}

.membershipPlanBox{border-radius: 5px;
  padding:30px 20px;
  position: relative;
  text-align: center;
background-color: #fff; border: 1px #EAECF0 solid;}

.membershipPlanBox h2{color: #101828;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px; margin-top: 0;}

  .membershipPlanBox h5 {color:#101828;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-top: 0 !important;}

  .membershipPlanBox li svg{margin-right: 10px;}

  .membershipPlanBox li{ justify-content: start !important; display: flex !important;}

  .membershipPlanBox .ant-list .ant-list-item-no-flex{display: inherit; color: #667085;
    font-size: 16px;}

  .courseVideoListWrap.activeBG{    background-color: #f1f1f1;}




   


