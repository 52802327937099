.Createcontestsection{
  background: #f6f6f8 ;
    padding: 20px 0px;
    

}   
.ContestBannerSection {    
    background-color: #212844;
    border-radius: 20px;
    padding: 0px 40px  ;  
    height: 290px;
  }
  .ContestbannerHeading{
    color: #ebeaea;
  }
  .contesthomeform{
    color: #212844;
    font-size: 24px;
    line-height: 28px;
    margin: 32px 0px;
  }

  