.clearfix{clear: both;}

.aboutCommunity{background: #fff;
    border-radius: 10px;
    padding: 1px 20px;
    margin-top: 20px;
    padding-bottom: 30px;
}

.aboutCommunity h2{border-bottom: 1px #f2f4f7 solid;
  padding: 15px 0; margin-bottom: 25px;
  margin-top: 0;  }

.pDiscription{color: #776d77;     margin-top: 5px;}

.communityTextWrap{margin-bottom: 25px;}

.communityTextWrap h4{margin-bottom: 5px;  margin-top: 15px;}

.communityLeftLink {
    background: #fff;
    border-radius: 10px;
}

.communityLeftLink .ant-list-bordered{border:none; border-radius: 0;}

.communityLeftLink  .ant-anchor-link a{color: #000; position: relative; padding-left: 20px;}

/* .communityLeftLink  .ant-anchor-link a::before{background: url(../../images/listIco.svg);
position: absolute; content: ''; left: 0px;
width: 16px;
height: 16px;
top: 2px;} */

.communityLeftLink  .ant-list-item:hover{cursor: pointer;}

/* .communityLeftLink  .ant-list-item:hover a{color:#db0a40;}

.communityLeftLink  .ant-list-item:hover a:before{background:#db0a40 url(../../images/listIcored.svg);     border-radius: 20px;} */

/* .communityLeftLink  .ant-list-item:hover{background:#fffafb;} */

.pDiscriptionBg{background: #fcf8f8;
    padding: 10px;
    margin-top: 25px;
    color: #000;}

    .joinCommunity{
        border-radius: 40px !important;
        background-color: #DB0A40 !important;
        color: #f7f8f9 !important;
        font-weight: 500;
        border: none;
      }
      
      
      .joinCommunity:hover{  background: #0a0a0a !important; color: #fff !important;}

    .contestDetailsBanner img{    max-width: 100%;
      width: 100%; height: 100%;}

    .contestDetailsBanner{border-radius: 16px; }

    .contestbannerImg{height: 340px; overflow: hidden;     border-radius: 16px;}