/* ===========================================================
                 Why Fantasy CSS Start Here 
=============================================================*/

.whyImgWrap img {
  width: 85%;
}

.whyImgWrap {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.commingImg img{width: 100%;}
.whySection {
  background: url(../../images/whyBg.svg);
  padding-top: 0 !important;
}

.whySection{margin-top: 20px;}

.topWinnersSection {
  background: url(../../images/winnersBG.svg) no-repeat; /*height: 300px; */
  background-size: 100%;
  padding-bottom: 30px;
}

.text-center {
  text-align: center;
}

.titleBG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-family: Roboto;
  font-weight: 900 !important;
  text-transform: capitalize;
  margin-top: 24px;
  margin-bottom: 15px !important;
}

.winnerWrap {
  width: 220px;
  height: 292px;
  transform: rotate(-12.252deg);
  border-radius: 16px;
  margin: 0 auto;
  margin-bottom: 60px;
  transition: all 0.5s ease;
}

.winnerWrap:hover {
  transform: rotate(-1.252deg);
  cursor: pointer;
}

.winnerCard {
  margin-top: 80px;
}

.winnerWrap .winnerUser {
  border-radius: 16px;
  width: 100%;
  height: 292px;
}

.winnerWrap:before {
  content: "";
  position: absolute;
  width: 214px;
  height: 292px;
  transform: rotate(9.421deg);
  border-radius: 16px;
  opacity: 0.3;
  background: #428dff;
  z-index: -1;
}

.winnerCard div {
}

.winnerWrap:hover:before {
  background: #db0a40;
}

.shield {
  width: 28px;
  height: 32px;
}

.firstBatch {
  width: 71px;
  height: 62px;
}

.winnerInfo {
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 1px;
}

.winnerName h3 {
  color: #fff;
  margin: 0;
  font-size: 21px;
}

.winnerName {
  padding-left: 10px;
}

.winnerNum {
  padding-right: 10px;
}

.battleCards {
  border-radius: 16px;
  background: #0f1836;
  border: 2px dashed #172453;
  margin-bottom: 30px;
}

.battleInfo {
  border-radius: 0px 0px 16px 16px;
  display: flex;
  padding: 12px 15px 20px;
  flex-direction: column;
  align-items: flex-start;
}

.battleImg img {
  width: 100%;
      height: 243px;
   }

.battleName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Jomhuria;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.battleFirst {
  color: #fff;
  text-align: center;
  font-family: Jomhuria;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  padding-right: 10px;
}

.battleSecond {
  color: #fff;
  text-align: center;
  font-family: Jomhuria;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  padding-right: 10px;
}

.battleImg {
  position: relative;
}

.battleCombImg{display: flex; flex-wrap: wrap; width: 100%;}

.battleFirstImg{max-width: 50%; flex:0 0 50%;}

.battleFirstImg img{    width: 100%; border-top-left-radius: 16px;
  max-height: 243px;}

.battleSecondImg{max-width: 50%; flex:0 0 50%;}

.battleSecondImg img{    width: 100%;
  border-top-right-radius: 16px;
  max-height: 243px;}

.battleDes {
  color: #fff !important;
  font-family: Helvetica;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  margin: 0;
}

.battleTimeline {
  display: flex;
  /* padding-right: 12px; */
  margin-bottom: 15px;
}

.battleTimeline span {
  display: flex;
  align-items: center;
  padding-right: 25px;
  color: #fff;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.battleTimeline span img {
  margin-right: 5px;
}

.joinNowBtn {
  color: #000;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  border-radius: 60px;
  background: #fff;
  padding: 6px 20px;
  cursor: pointer;
}

.joinNowBtn:hover {
  color: #000 !important;
}

.battleCardWrap {
  margin-top: 35px;
}

.battleCards:hover {
  border: 2px dashed #db0a40;
}

.battleCards:hover .battleInfo {
  background: #db0a40;
}

.googleAdsArea {
  height: 90px;
}

.googleAdsArea img{width: 100%;}

.googleAdsWrap {
  text-align: center;
  padding-top: 50px;
}

.ongoingBattleSection .sectionHeading h1 {
  margin-top: 0;
}

.upcomingCommingSoon {
  min-height: 450px;
  border-radius: 40px;
  margin-top: 50px;
  background: #1a2348;
  padding: 40px 0px 0 80px;
}

.upcomingBattleSection {
  padding-bottom: 0 !important;
}

.commingDetails {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.joinNowBtnBig {
  border-radius: 60px;
  background: #428dff;
  padding: 15px 24px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: inline-block;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
}

.joinNowBtnBig:hover {
  background: #db0a40;
}

.membershipCard {
  border-radius: 6px;
  border: 2px dashed #172453;
  background: rgba(223, 223, 255, 0.04);
  padding: 24px;
}

.planName {
  color: #fcfff5 !important;
  font-family: Roboto;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 30px !important;
  margin: 0;
}

.membershipPrice span {
  color: #fff;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2px;
}

.membershipPrice sup.validity {
  color: #fcfff5;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.membershipDetails {
  color: #acacac;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.joinNowSquareBtn {
  display: block;
  margin-top: 25px;
  border-radius: 4px;
  border: 1px solid #1b2340;
  background: #db0a40;
  padding: 16px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.joinNowSquareBtn:hover {
  background: #428dff;
  /* color: #101828; */
  color: #fff;
}

.joinNowSquareBlueBtn {
  display: block;
  margin-top: 25px;
  border-radius: 4px;
  border: 1px solid #1b2340;
  background: #428dff;
  padding: 16px;
  /* color: #101828; */
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.joinNowSquareBlueBtn:hover {
  background: #db0a40;
  color: #fff;
}

.memberTitle {
  color: #db0a40 !important;
  font-family: Inter;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.memberHeading {
  color: #fff;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.memberDes {
  color: #acacac;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.joinNowProgramBtn {
  padding: 16px 18px;
  margin-top: 25px;
  border-radius: 4px;
  background: #db0a40;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.memArrow {
  position: absolute;
  left: -34px;
}

.membershipRightCOntent {
  padding-left: 30px;
}

.smallHeading {
  color: #cdd7eb;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 30px;
}

.howDes {
  color: #cdd7eb;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.howItWorkWrap {
  margin-bottom: 100px;
}

.howStepsWrap {
  gap: 10px;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.stepBox {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex: 0 0 17%;
  height: auto;
  max-width: 17%;
  overflow: hidden;
}

.stepBoxCount {
  border-radius: 6px;
  border: 2px dashed #172453;
  background: rgba(15, 24, 54, 0.04);
}
.stepBoxCount:hover {
  border-color: #428dff;
  cursor: pointer;
}

.stepTitle {
  color: #428dff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  display: block;
}

.stepCount {
  color: #fff;
  font-family: Inter;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.googleAdsBanner {
  padding: 0;
}

.membershipCard:hover {
  border-color: #428dff;
}
.membershipRightCOntent:hover{

}

.googleAdsBanner img {
  width: 100%;
  height: 100%;
}

.stepNumber {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}

.stepImg {
  display: flex;
  width: 100px;
  /* height: 100px; */
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
}

.stepContent span {
  padding: 12px 24px;
  border-radius: 60px;
  background: rgba(66, 141, 255, 0.3);
  display: block;
}

.homeBannerSection {
  padding: 50px 0 0;
}

.bannerHeading {
  font-family: Inter;
  color: #fff !important;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-bottom: 30px !important;
}

.blueText {
  color: #428dff;
}

.bannerDes {
  color: #fff;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 35px;
}

.bannerBtn {
  display: inline-block;
  cursor: pointer;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 60px;
  margin-right: 15px;
  background: #db0a40;
  color: #fff;
}
.bannerBtn:hover {
  background-color: #428dff;
  color: #fff;
}

.performanceLive {
  color: rgba(255, 255, 255, 0.6);
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pr-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.bannerImg img {
  margin: 0 auto;
}
.bannerImg{height: 450px; overflow: hidden;}
.ant-carousel .slick-dots li {
  width: 7px;
}

.ant-carousel .slick-dots li button {
  width: 7px;
  border-radius: 20px;
  height: 7px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #db0a40;
  opacity: 1;
  width: 24px;
}

.ant-carousel .slick-dots {
  justify-content: start;
}

.ant-carousel .slick-dots-bottom {
  bottom: -15px;
}

/* .ant-layout *{ box-sizing: inherit !important;} */

.ratingReview .carousel-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 2rem;
  transition: transform 0.5s;
  -webkit-filter: grayscale(0) brightness(0.4);
  filter: grayscale(0) brightness(0.4);
}

.ratingReview .carousel-image-container {
  width: 15vw !important;
  position: relative;
}

.ratingReview .carousel-image-container .userRatingInfo {
  display: none;
}

.ratingReview {
  padding-bottom: 90px;
  padding-top: 90px;
  overflow: hidden;
}

.ratingReview .slick-center .carousel-image {
  width: 100%;
  transform: scale(1.2);
  -webkit-filter: grayscale(0) brightness(0.1);
  filter: grayscale(0) brightness(0.9);
}

.ratingReview .slick-center .userRatingInfo {
  display: block !important;
}

.ratingReview .userRatingInfo span {
  display: block;
}

.ratingReview .ant-carousel .slick-list {
  overflow: visible;
}

.ratingReview .userDesignation {
  color: #db0a40;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.ratingReview .userName {
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
  letter-spacing: -0.5px;
  margin: 0px 0 13px 0;
}

.ratingReview .userRatingInfo {
  position: absolute;
  top: 0;
  margin-left: 270px;
  z-index: 99;
  width: 181px;
}

.ratingReview .userSocial svg {
  margin-right: 15px;
}
.ratingReviewContent {
  max-width:41%;
  position: absolute;
  top: 0;
}
.ratingTitle {
  color: #db0a40 !important;
  font-family: Inter;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ratingHeading {
  color: #fff;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.ratingHeadingBold {
  color: #fff;
  margin: 0;
  font-family: Inter;
  font-size: 61px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.ratingDes {
  color: #acacac;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.ratingArrow{margin-right:20%;
  text-align: right;
}

.bannerSpace{padding-top: 130px; text-align: center;}

.bannerSpace img{width: 100%;}

.bannertopSpace img{width: 70%;margin-top:40px;align-content: 'center';}