.head {
    color: var(--gray-900, #101828);
    text-align: center;
    /* Display sm/Semibold */
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    /* 126.667% */
    text-align: center;
}

.subhead {
    color: var(--gray-500, #667085);
    text-align: center;
    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.inputfield,
.btn {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    float: none;
}

.card {
    width: 400px;
    margin: 0 auto;
    float: none;
    margin-top: 50px;
    padding: 50px;
}

.btn {
    display: flex;
    height: 44px;
    /* padding: 16px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 4px;
    background: #428DFF;
    color: #FFF;
    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}
.btn:hover
{ color: #FFF;

}
.link:hover{
    background: #428DFF;
    color: #FFF;
}