.Contactushead{
    color: #fff !important;
    font-size: 72px !important;
    font-weight: 800;
    margin-top: 10px;

}
.Contactusheadspan{
    color: #DB0A40 !important;

}
.smallHeading{
    font-size: 16px;
}

.bgcolor
{
/* display: flex; */
/* width: 1280px;
height: 800px; */
padding: 40px 0px  ;
justify-content: center;
align-items: center;
gap: 64px;
flex-shrink: 0;
background: #222D56;
margin-top: 40px;
border-radius: 2px;
}
.block
{
    margin-bottom: 10px;
}

 .rightWrap .head
{
color: #FFf !important;
/* Display md/Semibold */
font-size: 36px !important;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
margin-top: 10px;
text-align: left;
}
.rightWrap .para{
color: #E9E9E9;
/* Text xl/Normal */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
margin-top: -20px;
}
.form-label
{
color: #D8D8D8;
/* Text sm/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.form-input{
    padding: 70px !important;
}
.mybtn
{
display: flex;
height: 48px;
padding: 10px 14px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 60px;
background: #DB0A40;
margin-top: 30px;
}
.form-check-label
{
    color: var(--gray-500, #667085);
/* Text md/Normal */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
margin-top: 20px;
padding-left: 5px;
}
.linkprivacy
{
    color: var(--gray-500, #667085);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
text-decoration-line: underline;
margin-top: 20px;
}
.form-check-input
{
border-radius: 6px;
border: 1px solid rgba(208, 213, 221, 0.20);
background: rgba(255, 255, 255, 0.10);
width: 20px;
height: 20px;
margin-top: 20px;
}
.myform
{
display: flex;
padding: 8px 8px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px solid rgba(255, 255, 255, 0.20);
background: rgba(255, 255, 255, 0.10);
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.rightWrap{
    text-align: left;
    align-items: flex-start;
    padding: 0px 40px;
}
.rightWrap h3{
    text-align: #fff !important;
}
.myform:focus {
    background-color: #E9E9E9;
}
