.InnerBannerSection{padding-top: 50px; padding-bottom: 70px; position: relative;}

.InnerBannerSection .smallHeading{  max-width: 617px;
    margin: 0 auto; 
    line-height: 25px;}

.contestBtn{color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    border-radius: 60px;
    background: #DB0A40;
    position: absolute;
    padding: 5px 17px;
    top: 0;
    right: 0;}

.contestBtn:hover{
    background-color: #428DFF;
    color: #FFF;
}

.innerContestPadd{padding-bottom: 100px;}

.contestSpace{padding-bottom: 30px;}

.contestGraphic{position: absolute;
    right: 0;
    width: 25%;
    top: -40px;
    z-index: -1;}

.joinNowBtn:hover{
    background-color: #428DFF;
    color: #FFF !important;
}




/* JOIN CONTEST PAGE */

.JoinNowsection{
    margin-top: 90px;
}
.joinbattlesection{
    margin: 30px 0px;
}
.leftwrap{
    border-radius: 8px;
    background-color:#141C39;
    margin: 0px 3% 0px 0px;
    padding: 5px 5px 10px 30px;
    text-align: left !important;
    line-height: 24px;
    display: flex;
    flex-direction: column;
}
.leftwrapinner{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.heading{
    margin-right: 15%;
    margin-left: 0 auto;
    padding: 0px auto;
    font-size: 24px;
    color: #428DFF;
    font-weight: 600;
}
.time{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 25px 15px;
    color: #FFFFFF;
}
.contestdescriptionwrap p{
    font-size: 14px;
    font-weight: 400;
   }
.contestdescriptionwrap h4{
    color: #DB0A40;
    font-size: 20px;
    font-weight: 600;
}
.joincontestbtn{
    background-color: #428DFF;
    padding: 16px 80px;
    color: #FFF;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
}
.joincontestbtn:hover{
    background-color: #DB0A40;
    color: #fff;

}
.joinbtnwrapper{
    margin-top: 40px;
}

/* JOIN CONTESTENT PAGE */
.invitenote{
    margin-top: -15px;
    font-size: 16px;
    font-weight: 400;
}

.contestentselector ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

.contestentselector label {
  border: 2px solid #141C39;
  /* padding: 10px; */
  display: block;
  position: relative;
  /*     */
  cursor: pointer;
  border-radius: 50%;
}

.contestentselector label:before {
  background-color: white;
  /* color: white; */
  /* content: " "; */
  display: block;
  border-radius: 50%;
  border: 1px solid #428DFF;
  position: absolute;
  /* top: -5px;
  left: -5px;
  width: 25px;
  height: 25px; */
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.contestentselector label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  /* transform-origin: 50% 50%; */
}

.contestentselector :checked + label {
  border-color: #428DFF;
}

.contentName p{text-align: center;}

.contestentselector :checked + label:before {
  /* content: "✓"; */
  background-color: grey;
  /* transform: scale(1); */
}

.contestentselector :checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}
.contestentselector{
    margin-left: -3%;
}
.icontag{
  cursor: pointer;
  color: #fff;
  background-color: #DB0A40;
  border-radius: 10% ;
  padding: 4px;
}
.icontag:hover{
  background-color: #428DFF;
}

/* <-------------SOCIAL SHARE BUTTON -------------> */
.boxwrap{
  height: 50px;
 padding: 7px 1px;
 justify-content: space-around;
 background-color: 
 #F9F9F9;
 /* width: 10%; */
 border: 1px solid 
 #D0D5DD;
 border-radius: 4px;
}
.boxwrap p{
  padding-top: 5px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 400;
  color: 
  #667085;
  /* overflow: hidden; */
}
.socialinput{
  /* padding-left: 10px; */
    background-color: 
    #F9F9F9;
    border-radius: 5px;
    font-size: 15px ;
    height: 10px;
    /* border: 1px solid #eae8e8; */
     width: 70%;
     border: none;
}
.socialinput P{
 margin-top: -10px;
}
.socialinput:focus-visible{
  border: none;
}
.socialcopybtn {
  background-color: #428DFF;
  border-radius: 4px;
  border: none;
  padding: 0px 5% !important;
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
/* <-------------SOCIAL SHARE BUTTON -------------> */


