@media screen and (max-width:768px){
  .upcomingCommingSoon{ padding: 45px 30px;}
  .ratingReview{ padding-top: 30px; padding-bottom: 30px;}
  .ratingHeading{font-size: 24px;}
  .ratingHeadingBold{font-size: 25px;}
  .ratingArrow svg{display: none;}
  .ratingArrow{margin-top: 15px;}
  .ratingDes{display: none;}
  .bannerSpace{padding-top: 80px;}
  .footerMenulist{padding-inline: 0;}
  .headerTop{min-height: 64px; height: inherit;}
  .homeBannerSection{padding: 15px 0 0;}
}

@media screen and (max-width:515px){
.bannerImg img{ display: none !important;}
.howDes{font-size: 22px;}
.stepBox{width: 32%; padding: 20px;}
}