/* ===========================================================
                 Common CSS Start Here 
=============================================================*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Jomhuria&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body{background-color: #0b122a; color: #fff;font-family: 'Inter', sans-serif;font-size: 14px;}

.text-white{color: #fff;}

.ant-menu-item-active:hover,
.ant-menu-item-selected:active,
.ant-menu-item-selected,
.ant-menu-submenu-selected:hover{background-color: transparent !important; color: #DB0A40 !important;}

.ant-menu-item-selected{color:#DB0A40 !important;}

.ant-menu-item:hover::after,.ant-menu-item::after{border-bottom: none !important;}

.container{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}

.section-padding{padding: 50px 0;}

.ant-tabs-top >.ant-tabs-nav::before{    border-bottom: 1px #172453 solid !important;}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{  color: #428DFF;}

.ant-tabs .ant-tabs-tab{ color: #fff;}

.text-left{text-align: left;}

/* ============================================================
                 Common CSS End Here 
=============================================================*/
.text-white{color: #fff;}

.headerTop{
padding-top: 0px;
/* background: #101828; */
background: #0b122a ;
}

.menuListMenu{    border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: #080c1b !important;
 }
.headerProfileMenu .ant-menu-vertical{border-inline-end: none;}
.headerWrap{}

.d-flex{display: flex;justify-content: flex-end;}

.menuList{color: #fff;
  background: transparent;
  display: flex;
  line-height: 43px !important;
  height: 45px;
  justify-content: end;
  padding: 0 15px;}

.menuListMenu li:hover{color:#fff ;}


.loginBtn{border-radius: 60px !important;
  background-color: #fff !important;
    color: #101828 !important;
  margin-right: 10px !important;
  padding:  0 px 90px !important ;
  font-size: 16px;
  
  font-weight: 500;
}


.loginBtn:hover{  background: #DB0A40 !important; color: #fff !important;}


.signupBtn{border-radius: 10px !important; background: #DB0A40;
   color: #fff !important; 
   font-size: 500;
   font-size: 16px;
 }

.signupBtn:hover{background: #428DFF !important; color: #101828 !important;}

.logo{display: flex;
  align-items:center; cursor: pointer;}


  .sectionHeading{}

  .sectionHeading h1{ color: #DB0A40;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;}
  
  .sectionTitle p{color:#ACACAC;}
  
  .sectionTitle p,.sectionTitle p+p{margin-bottom: 25px;}

/* ===========================================================
                 Footer CSS Start Here 
=============================================================*/

.footerWrap{background: #070C20; padding:30px 0;     padding-bottom: 10px; position: relative;  
  background-color: #0b122a !important;
    border-top: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right,#070C20, #131F58, #233382, #131F58, #070C20);
  
}

.footerSocial{display: flex; margin-top: 15px;}

.footerSocial .ant-anchor{padding-inline: 0 10px;}

.footerMenulist{background: none;     padding-inline: 50px;
  color: #fff;}

.footerMenulist li{font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; 
}

.footerMenulist li a:hover {background-color: transparent !important; color: #DB0A40 !important;}

.footerListHeading{    font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #fff;}

.footerDiscription{font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.25px;}

.footerCopyright{font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.29px;}

.footerCopyrightlist{background: none; display: flex;
  justify-content: end;}

.footerCopyrightlist li a{color: #fff !important;}

.footerCopyrightlist li a:hover{color:#DB0A40 !important;}


.loginModal .anticon-close svg{color: #000;
  border: 1px #000 solid;
  border-radius: 20px;
  padding: 2px 4px;
  width: 12px;}

  .loginModal  .modalHeading{color: #101828;
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 6px;}


  .loginModal  .ModalSmallHeading{color: #667085;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 0;}


  .loginForm label{    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;}

    .loginForm input[type="text"] {border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    height: 40px;}

    .loginForm  .ant-input-affix-wrapper{padding:0px 11px;}

    .loginForm input[type="password"]{ border: none; height: 40px;}

    .loginForm .ant-input-affix-wrapper input[type="text"] {border: none;}

    .forgotLink{color:#6941C6;
         font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;
      line-height: 20px;}
      .forgotLink:hover{color: #428DFF;}


      .forgotLinkWrap .ant-form-item-control-input-content{display: flex; justify-content: space-between;}

    .loginBtnModal{border-radius: 4px;
      background: #428DFF;
      width: 100%;
      height: 45px;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;}

    .notSignLink{color: #DB0A40;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;}

    .dontAccountWrap{display: flex;
      align-items: baseline;
      justify-content: center;
      margin-top: 15px;}

    .dontText{color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    margin-right: 5px;}

  .fllogo{position: absolute; left: 0; top: 70px;}

  .frlogo{position: absolute; right: 0; top: 0;}

.footerBottom{    padding-top: 60px;}

.footerBottom .ant-menu-item a::before{background-color: #fff;
    content: "";
    width: 1px;
    height: 100%;}

.footerBottom .ant-menu-item:first-child a::before{content: none;}

.footerBottom .ant-menu-hzontal{line-height: 18px;}

.ant-menu-light.ant-menu-root.ant-menu-vertical{border-inline-end:none;}

.headerProfileMenu .ant-menu-vertical p{ border-bottom: 1px #b7b7b7 solid;
  padding-bottom: 10px;
  margin-top: 10px;}

.headerProfileMenu .ant-menu-vertical p:last-child{border-bottom: none; padding-bottom: 0; margin-bottom: 0;}

.ant-menu-horizontal{ line-height: 15px;}

.pdb-0{padding-bottom: 0;}

.contactPage img{object-fit: none;}

.CardField{ border: 1px solid #eae8e8;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 10px;}

.ElementsApp{height: 45px; border-radius: 4px;}

.saveCardForm iframe{height: 80px !important;
  margin-bottom: 20px !important;}

.CardBrandIcon{left: 10px;}

.InputContainer .InputElement{left: 10px;}

.icontag svg{font-size: 17px;}

.helpForm input,.helpForm textarea,.helpForm .ant-select .ant-select-selector{background-color: #f9f9f9;
  border-radius: 5px;
  height: 40px;
  font-size: 15px;
  border: 1px solid #eae8e8;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;}

  .helpForm textarea{height: inherit !important;}

  .courseLearn p:first-child{color: #000;
    font-family: Helvetica;
    margin-bottom: 13px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;}

    .courseLearn p:nth-child(2) {color: #667085;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      margin-top: 0;}

  .courseLearn p strong{font-weight: 600;}

  .courseDurationWrap {    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d2d2d2 solid;
    margin-bottom: 10px;
    padding-bottom: 10px;}

 .courseNameText{font-weight: 600;}

 .courseDurationWrap:last-child{border-bottom: none; margin-bottom: 0;}








  /* CSS FOR HEADER AUTH TOP STARTS HERE */
  /* ----------------------------------------- */

  /* .text-white{color: #FFF;} */

.headerAuthTop{
padding-top: 0px;
/* background: #101828; */
background: #FFFFFF;
margin-bottom: 22px;
height: 90px !important;
}

.menuListMenuAuth{
  color: #000 !important;
  font-weight: 400;
  /* border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.10); */
  /* background: #080c1b !important; */
  margin-right: 1px;}

/* .headerWrapAuth{  border: none;
 
} */

.d-flex{display: flex;justify-content: flex-end;}

.menuListAuth{    border: none;
  background: transparent;
  display: flex;
  line-height: 38px !important;
  height: 38px;
  justify-content: end;
  padding: 0 15px;}

  .menuListMenu li{
    border: none;
  }

.menuListMenu li:hover{color:#fff ;}

.menuListAuth{
  border: none;
}


.JoinContest{
  border-radius: 40px !important;
  background-color: #DB0A40 !important;
  color: #f7f8f9 !important;
  margin-right: 10px !important;
  font-weight: 500;
  border: none;
}


.JoinContest:hover{  background: #0a0a0a !important; color: #fff !important;}


.signupBtn{border-radius: 40px !important; background: #DB0A40;
   color: #fff !important; 
 }

.signupBtn:hover{background: #DB0A40 !important; color: #fff !important;}

.logo{display: flex;
  align-items:center; cursor: pointer;}


  .sectionHeading{}

  .sectionHeading h1{ color: #DB0A40;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;}
  
  .sectionTitle p{color:#ACACAC;}
  
  .sectionTitle p,.sectionTitle p+p{margin-bottom: 25px;}

  /* .ant-avatar-image{width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border: 2px solid rgb(244, 235, 255);} */




    

