/* .ContestBannerSection {    
    background-color: #212844;
    border-radius: 20px;
    padding: 0px 40px  ;  
    height: 290px;
  } */
  
  .AuthBannerSection{
    background: #212844;
    background-image: url("../images/Carouselbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    margin-top: -2rem;
    height: 340px ;
  }
  .CommunityBannerSection{
    /* background: #212844;
    background-image: url("../images/Carouselbg.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    margin-top: -2rem;
    height: 340px ;
    overflow: hidden;
  }

  .CommunityBannerSection img{    width: 100%;
    height: 100%;}

  .bannerImg img {
    margin: 0 auto;
  }
  
  .ant-carousel .slick-dots li {
    width: 7px;
  }
  
  /* .ant-carousel .slick-dots li button {
    width: 7px;
    border-radius: 20px;
    height: 7px;
  } */
  
  .ant-carousel .slick-dots li.slick-active button {
    background: #db0a40;
    opacity: 1;
    width: 24px;
  }
  
  .ant-carousel .slick-dots {
    justify-content: start;
  }
  
  .ant-carousel .slick-dots-bottom {
    bottom: 10px;
    /* left: 3%; */
  }
  .contestbannerImg img{
    /* padding-right:40px; */
    justify-items: center;
    justify-content: space-around;
  }
  .contestbannerBtn{
    background-color: #fff;
    padding: 8px 27px;
    color: #000000;
    border-radius: 60px;
    font-size: 18px;
    font-weight: 400;
  }
  .contestbannerBtn:hover{
    background-color: #db0a40;
    color: #fff;

  }
  .contestsectiontitle{
    font-size: 24px;
    font-weight: 400;
   margin-bottom: -2px;
  }
  .contestsectionsubtitle{
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
  }
  .contestsectionheading{
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }
  .contestbannerImg img{
    padding-top: 0px;
  }
  /* .leftbannerwrap{
    margin-top: -30px;
  } */