.head {
    color: var(--gray-900, #3C466B) !important;
    text-align: center;
    /* Display sm/Semibold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    /* 126.667% */
    text-align: center;
}

.subhead {
    color:  #428DFF !important;
    text-align: center;
    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

/* .inputfield,
.btn {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    float: none;
    padding: 10px;
} */

.card {
    width: 400px;
    margin: 0 auto;
    float: none;
    margin-top: 50px;
    padding: 50px;
}

.btn {
    /* display: flex; */
    height: 44px;
    /* padding: 10px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 40px !important;
    background: #DB0A40 !important;
    color: #FFF;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    list-style: none;
    text-decoration: none !important;
    border: none;
    /* 150% */
}
.btn:hover
{ color: #FFF;
    background-color: #428DFF !important;
    cursor: pointer;

}
.inputemail{
    color: #FFF;
}
