.notificationWrap{background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
     overflow: initial!important;
    padding: 24px!important;
margin-bottom: 20px;
}

.notificationList{align-items: flex-start;
    display: flex;
    justify-content: space-between;}

.notificationMsg{ width: 80%;}

.notificationMsg h3{color: #DB0A40;     margin-top: 0;}

.notificationMsg p{ margin-bottom: 0;}

.notificationDate{color: #979393;}